import DfBrand from "@/vue/domain/brand/df-brand";
import DfStore from "@/vue/domain/store/df-store";
import Utils from "@/vue/infrastructure/utils/helpers";
import { Component, Vue } from "vue-property-decorator";
import DfContent from "@/vue/domain/content/df-content";

const DfSectionLinksComponent = () => Utils.externalComponent2("df-section-links");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfPushCardComponent = () => Utils.externalComponent2("df-push-card");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfStoreCard: DfStoreCardComponent,
    DfSectionLinks: DfSectionLinksComponent,
    DfPushCard: DfPushCardComponent,
  },
})
export default class DfPageNotificationsComponent extends Vue {
  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get pushesList(): Array<DfContent> {
    const storePush: Array<DfContent> = this.$store.getters.storeContents.filter((content: DfContent) => content.type.code === "PSH");
    const brandPush: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "PSH");
    const pushesList: Array<DfContent> = [...storePush, ...brandPush].sort((contentA: DfContent, contentB: DfContent) => {
      const dateA: string = Utils.getPropertyValue(contentA, Utils.PROPERTY_DATE, "DATE");
      const dateB: string = Utils.getPropertyValue(contentB, Utils.PROPERTY_DATE, "DATE");
      return dateA < dateB ? 1 : -1;
    });

    return pushesList.filter((push: DfContent) => {
      const stored: any = this.storedPushesList.find((storedPush: any) => storedPush.alias === push.alias);
      return stored ? !stored.deleted : true;
    });
  }

  get storedPushesList(): Array<any> {
    return this.$store.getters.storedPushesList;
  }

  set storedPushesList(storedPushesList: Array<any>) {
    this.$store.dispatch("setStoredPushesList", storedPushesList);

    if (storedPushesList.length > 0) {
      Utils.localStorageSetItem("df-pushes-list", JSON.stringify(this.storedPushesList));
    } else {
      Utils.localStorageRemoveItem("df-pushes-list");
    }
  }

  beforeDestroy() {
    this.pushesList.forEach((push: DfContent) => {
      const newPush: boolean = this.storedPushesList.findIndex((storedPush: any) => storedPush.alias === push.alias) === -1;
      if (newPush) {
        this.storedPushesList = [...this.storedPushesList, { alias: push.alias, deleted: false }];
      }
    });
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }
}
